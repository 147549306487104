<template>
  <div id="user-profile">
    <b-navbar
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <navbar-hasil />
    </b-navbar>
    <b-container class="mb-3">
      <br />
      <br />
      <center>
        <b-card style="margin-left: 30%; margin-right: 30%">
          <b>Skor Saya </b>
          <b-badge
            variant="light-danger"
            class="ml-1"
            v-if="hasilUjian.is_passed == false"
            >Tidak Lolos</b-badge
          >
          <b-badge variant="light-success" class="ml-1" v-else>Lolos</b-badge>
          <h1 class="text-primary mt-2 mb-1">
            <b> {{ hasilUjian.my_score }} </b>
          </h1>
          <b-card-body
            class="bg-primary"
            v-if="
              hasilUjian.is_last_subtest == true &&
              hasilUjian.rasionalisasi != null &&
              hasilUjian.rasionalisasi.ranking
            "
          >
            <b-row>
              <b-col md="6" class="text-white">
                Rank Saya <br />
                <span v-if="hasilUjian.rasionalisasi.ranking[0] != null">
                  <b-row>
                    <b-col
                      v-for="(item, i) in hasilUjian.rasionalisasi.ranking"
                      :key="i"
                    >
                      <h6 class="text-white">{{ item.subcat_name }}</h6>
                      <b>
                        {{ item.ranking.ke }}
                      </b>
                      /{{ item.ranking.dari }}
                    </b-col>
                  </b-row>
                </span>
                <span v-else>
                  <b>
                    {{ hasilUjian.rasionalisasi.ranking.ke }}
                  </b>
                  /{{ hasilUjian.rasionalisasi.ranking.dari }}
                </span>
              </b-col>
              <b-col md="6">
                <b-button variant="outline-light" @click="lihatRank"
                  >Lihat Rank Lengkap</b-button
                >
              </b-col>
            </b-row>
          </b-card-body>
          <h6 v-else>
            Passing grade : <b> {{ hasilUjian.passing_grade }}</b>
          </h6>
        </b-card>

        <b-card
          style="margin-left: 7%; margin-right: 7%"
          v-if="
            hasilUjian.is_last_subtest == true &&
            hasilUjian.rasionalisasi != null
          "
        >
          <!-- UTBK -->
          <div class="" v-if="paketDetail.rasionalisasi_type == 'utbk'">
            <div class="" v-if="hasilUjian.rasionalisasi != null">
              <b-card style="margin-left: 7%; margin-right: 7%">
                <b-row>
                  <b-col
                    md="6"
                    class="mt-3"
                    v-for="(item, i) in hasilUjian.hasil_rasionalisasi"
                    :key="i"
                  >
                    <table class="table table-bordered">
                      <tr>
                        <th
                          colspan="2"
                          class="text-center bg-primary text-white"
                        >
                          Pilihan {{ item.pilihan_ke }}
                        </th>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td>{{ item.university_info.name }}</td>
                      </tr>
                      <tr>
                        <td>Target Jurusan</td>
                        <td>{{ item.university_info.major_name }}</td>
                      </tr>
                      <tr>
                        <td>Nilai Ambang Batas</td>
                        <td>{{ item.nilai_ambang_batas }}</td>
                      </tr>
                      <tr>
                        <td>Total Skor</td>
                        <td>{{ item.score_total }}</td>
                      </tr>
                      <tr>
                        <td>Peluang Lulus</td>
                        <td>
                          <b-badge
                            variant="light-danger"
                            class="ml-1"
                            v-if="item.peluang_lulus == false"
                            >Tidak Lolos</b-badge
                          >
                          <b-badge variant="light-success" class="ml-1" v-else
                            >Lolos</b-badge
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Peluang Masuk</td>
                        <td>
                          <b-badge variant="light-secondary" class="ml-1">{{
                            item.evaluasi.status_peluang_masuk
                          }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td>Pesan</td>
                        <td>
                          <p>{{ item.evaluasi.message }}</p>
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </div>

          <!-- Mandiri -->
          <div class="" v-if="paketDetail.rasionalisasi_type == 'mandiri'">
            <div class="" v-if="hasilUjian.rasionalisasi != null">
              <b-row>
                <b-col
                  md="6"
                  class=""
                  v-for="(item, i) in hasilUjian.rasionalisasi.selected_majors"
                  :key="i"
                >
                  <table class="table table-bordered">
                    <tr>
                      <th colspan="2" class="text-center bg-primary text-white">
                        Pilihan {{ i + 1 }}
                      </th>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td>{{ item.school.name }}</td>
                    </tr>
                    <tr>
                      <td>Target Jurusan</td>
                      <td>{{ item.name }}</td>
                    </tr>
                    <tr>
                      <td>Nilai Ambang Batas</td>
                      <td>
                        {{
                          item.passing_grade_percentage
                            ? passing_grade_percentage
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Skor</td>
                      <td>{{ item.nilai_akhir }}</td>
                    </tr>
                    <!-- <tr>
                        <td>Peluang Lulus</td>
                        <td><b-badge variant="light-danger" class="ml-1" v-if="item.peluang_lulus == false">Tidak Lolos</b-badge> <b-badge variant="light-success" class="ml-1" v-else>Lolos</b-badge></td>
                      </tr>
                      <tr>
                        <td>Peluang Masuk</td>
                        <td>
                          <b-badge variant="light-secondary" class="ml-1">{{ item.evaluasi.status_peluang_masuk }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td>Pesan</td>
                        <td>
                          <p>{{ item.evaluasi.message }}</p>
                        </td>
                      </tr> -->
                  </table>
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- SMA -->
          <b-card v-if="paketDetail.rasionalisasi_type == 'sma'">
            <b-table-simple responsive bordered>
              <b-tr style="background-color: #fef000">
                <b-th colspan="6" class="text-center">
                  <h1 class="text-dark">BIMBINGAN BELAJAR</h1></b-th
                >
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h2 class="text-dark">{{ hasilUjian.title }}</h2></b-th
                >
              </b-tr>
              <b-tr>
                <th>Nama</th>
                <td colspan="5">{{ hasilUjian.name }}</td>
              </b-tr>
              <b-tr>
                <th>Sekolah</th>
                <td colspan="5">
                  {{
                    hasilUjian.school_origin
                      ? hasilUjian.school_origin.nama_sekolah
                      : "-"
                  }}
                </td>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                >
              </b-tr>
              <b-tr>
                <b-th
                  rowspan="2"
                  class="text-center"
                  style="background-color: #bebfc3"
                  ><h3 class="text-primary"><b> TRY OUT</b></h3></b-th
                >
                <b-th
                  colspan="5"
                  class="text-center text-dark"
                  style="background-color: #a8a7a9"
                >
                  <b> NILAI PER MAPEL SATUAN </b>
                </b-th>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th class="text-primary">BENAR</b-th>
                <b-th class="text-primary">SALAH</b-th>
                <b-th>Nilai TO</b-th>
                <b-th>*KK</b-th>
                <b-th>Keterangan</b-th>
              </b-tr>
              <b-tr v-for="(item, i) in hasilUjian.nilai_per_mapel" :key="i">
                <th>{{ item.ujian_name }}</th>
                <td>{{ item.jumlah_benar }}</td>
                <td>{{ item.jumlah_salah }}</td>
                <td>{{ item.nilai }}</td>
                <td>{{ item.grade ? item.grade : "-" }}</td>
                <td>{{ item.keterangan ? item.keterangan : "-" }}</td>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6"></b-th>
              </b-tr>
              <b-tr style="background-color: #fef000">
                <th>JUMLAH NILAI</th>
                <td colspan="5">{{ hasilUjian.jumlah_nilai }}</td>
              </b-tr>
              <b-tr style="background-color: #fef000">
                <th>RATA-RATA NILAI</th>
                <td colspan="5">{{ hasilUjian.nilai_rata }}</td>
              </b-tr>
              <b-tr style="background-color: #fef000">
                <th>*KK RATA-RATA NILAI</th>
                <td colspan="5">{{ hasilUjian.nilai_rata_grade }}</td>
              </b-tr>
              <b-tr style="background-color: #fef000">
                <th>RANGKING</th>
                <td colspan="5">{{ hasilUjian.ranking.ke }}</td>
              </b-tr>
            </b-table-simple>
          </b-card>

          <!-- Kedinasan -->
          <b-card v-if="paketDetail.rasionalisasi_type == 'kedinasan'">
            <b-table-simple responsive bordered>
              <b-tr style="background-color: #fef000">
                <b-th colspan="6" class="text-center">
                  <h1 class="text-dark">BIMBINGAN BELAJAR</h1></b-th
                >
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h2 class="text-dark">Kedinasan</h2></b-th
                >
              </b-tr>
              <b-tr>
                <th>Nama</th>
                <td colspan="5">{{ hasilUjian.rasionalisasi.user_name }}</td>
              </b-tr>
              <b-tr>
                <th>Jurusan</th>
                <td colspan="5">
                  {{ hasilUjian.rasionalisasi.jurusan_pilihan.school.name }}
                </td>
              </b-tr>
              <b-tr>
                <th>Asal Sekolah</th>
                <td colspan="5">
                  {{
                    hasilUjian.rasionalisasi.school_origin
                      ? hasilUjian.rasionalisasi.school_origin.nama_sekolah
                      : "-"
                  }}
                </td>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                >
              </b-tr>
              <b-tbody
                v-for="(item, i) in hasilUjian.rasionalisasi.subcats"
                :key="i"
              >
                <b-tr>
                  <b-th
                    colspan="6"
                    class="text-dark"
                    style="background-color: #a8a7a9"
                  >
                    <h4>
                      <b> {{ item.name }} </b>
                    </h4>
                  </b-th>
                </b-tr>
                <b-tr
                  v-if="item.items"
                  v-for="(data, index) in item.items"
                  :key="index"
                >
                  <b-th>{{ data.ujian_name }}</b-th>
                  <b-th>{{ data.nilai }}</b-th>
                  <b-th>{{
                    data.is_passed == true ? "Lulus" : "Tidak Lulus"
                  }}</b-th>
                </b-tr>
                <b-tr style="background-color: #c5d9f1">
                  <b-th class="text-danger">SKOR</b-th>
                  <b-th class="text-danger">{{
                    item.is_using_percentage == true
                      ? item.sum_percentage_nilai
                      : item.total_nilai
                  }}</b-th>
                  <b-th class="text-danger">{{
                    item.is_passed == true
                      ? "Lulus"
                      : "Tidak Lanjut Ke Tahap Selanjutnya"
                  }}</b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>

          <!-- Bintara -->
          <b-card v-if="paketDetail.rasionalisasi_type == 'bintara'">
            <b-table-simple responsive bordered>
              <b-tr style="background-color: #fef000">
                <b-th colspan="6" class="text-center">
                  <h1 class="text-dark">BIMBINGAN BELAJAR</h1></b-th
                >
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h2 class="text-dark">Bintara</h2></b-th
                >
              </b-tr>
              <b-tr>
                <th>Nama</th>
                <td colspan="5">{{ hasilUjian.rasionalisasi.user_name }}</td>
              </b-tr>
              <b-tr>
                <th>Asal Sekolah</th>
                <td colspan="5">
                  {{
                    hasilUjian.rasionalisasi.school_origin
                      ? hasilUjian.rasionalisasi.school_origin.nama_sekolah
                      : "-"
                  }}
                </td>
              </b-tr>
              <b-tr style="background-color: #bebfc3">
                <b-th colspan="6" class="text-center">
                  <h4 class="text-dark">HASIL TRY OUT</h4></b-th
                >
              </b-tr>
              <b-tbody
                v-for="(item, i) in hasilUjian.rasionalisasi.subcats"
                :key="i"
              >
                <b-tr>
                  <b-th
                    colspan="6"
                    class="text-dark"
                    style="background-color: #a8a7a9"
                  >
                    <h4>
                      <b> {{ item.name }} </b>
                    </h4>
                  </b-th>
                </b-tr>
                <b-tr
                  v-if="item.items"
                  v-for="(data, index) in item.items"
                  :key="index"
                >
                  <b-th>{{ data.ujian_name }}</b-th>
                  <b-th colspan="2">{{ data.nilai }}</b-th>
                  <!-- <b-th>{{ data.is_passed == true ? "Lulus" : "Tidak Lulus" }}</b-th> -->
                </b-tr>
                <b-tr style="background-color: #c5d9f1">
                  <b-th class="text-danger">SKOR</b-th>
                  <b-th class="text-danger">{{ item.total_nilai }}</b-th>
                  <b-th class="text-danger">{{
                    item.is_passed == true
                      ? "Lulus"
                      : "Tidak Lanjut Ke Tahap Selanjutnya"
                  }}</b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>

          <!-- Pembahasan -->
          <b-overlay :v-show="loading">
            <div class="">
              <h4 class="mt-4 text-center">
                <u> Pembahasan </u>
              </h4>

              <b-row class="">
                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive">
                      <tr class="bg-primary text-white" variant="primary">
                        <th>No</th>
                        <th>Jenis</th>
                        <th>Subtes</th>
                        <th>Jumlah Soal</th>
                        <th>B</th>
                        <th>S</th>
                        <th>K</th>
                        <th>T</th>
                        <th>Pembahasan</th>
                      </tr>
                      <tr
                        v-if="listRaport.scores"
                        v-for="(item, i) in listRaport.scores"
                        :key="i"
                      >
                        <td>{{ i + 1 }}</td>
                        <td>{{ item.ujian_shortname }}</td>
                        <td>{{ item.ujian_name }}</td>
                        <td>{{ item.jumlah_soal }}</td>
                        <td>{{ item.jumlah_benar }}</td>
                        <td>{{ item.jumlah_salah }}</td>
                        <td>{{ item.jumlah_kosong }}</td>
                        <td>
                          <b-badge variant="success">
                            {{ item.nilai }}
                          </b-badge>
                        </td>
                        <td>
                          <b-button
                            variant="outline-primary"
                            @click="lihatPembahasan(item)"
                            v-if="item.hasil_id != null"
                            >Pembahasan</b-button
                          >
                          <b-button variant="outline-primary" disabled v-else
                            >Pembahasan</b-button
                          >
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7" class="text-center">Total</td>
                        <td colspan="2" class="text-center">
                          <b-badge variant="danger" class="text-center">
                            {{ sumScores }}
                          </b-badge>
                        </td>
                      </tr>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </b-card>
      </center>
    </b-container>
  </div>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BContainer,
  BCard,
  BCardBody,
  BCardFooter,
  BBadge,
  BButton,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";

import NavbarHasil from "./components/NavbarHasil.vue";
/* eslint-disable global-require */
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BContainer,
    BCard,
    BCardBody,
    BCardFooter,
    BBadge,
    BButton,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    NavbarHasil,
  },

  computed: {
    hasilUjian() {
      return this.$store.state.ujian.hasilUjian;
    },
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
  },

  data() {
    return {
      listRaport: null,
      loading: false,
      sumScores: 0,
    };
  },

  methods: {
    lihatRank() {
      this.$router.push({ name: "ranking-siswa-tryout" });
    },
    getHasil() {
      this.loading = true;
      let params = {
        paket_id: this.paketDetail.id,
      };
      this.$store
        .dispatch("raport/list", params)
        .then((res) => {
          this.loading = false;
          this.listRaport = res.data.data;
          let sumScores = this.listRaport.scores.reduce((total, item) => {
            return (total += item.nilai);
          }, 0);
          this.sumScores = sumScores;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },

    lihatPembahasan(item) {
      this.$store.commit("ujian/SET_HASIL", item);
      this.$router.push({ name: "pembahasan-soal-tryout" });
    },
  },

  created() {
    if (this.hasilUjian == null) {
      this.$router.go(-1);
    }
    this.getHasil();
  },
};
</script>
